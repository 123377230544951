<template>
  <div class="priority">
    <dropdown-wrap-vue
      @onClickOutside="onClickOutside">
      <div class="priority-wrap">
        <b-button
          class="priority-main"
          @click="openSetting">
          <div class="priority-container">
            <span v-if="showOptions"></span>
            <!-- <p class="priority-label">{{priorities.filter(e => e.value == localValue)[0].alias}}</p> -->
            <p class="priority-label">{{priorities.find(e => e.value == localValue).alias}}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-if="showOptions" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
          </div>
        </b-button>
        <div
          v-if="open && showOptions"
          class="priority-options">
          <ul class="priority-list">
            <li
              @click="changePriority(priority)"
              v-for="(priority,index) of priorities"
              :key="index">{{priority.alias}}</li>
          </ul>
        </div>
      </div>
    </dropdown-wrap-vue>
    <!-- <p
      class="priority-label"
      v-else>{{priorities.filter(e => e.value == localValue)[0].alias}}</p> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'

export default {
  name: 'Priority',
  components: {
    DropdownWrapVue
  },
  props: ['localValue', 'priority', 'onChange', 'showOptions'],
  data () {
    return {
      open: false,
      priorities: [
        { alias: 'Low', value: 0 },
        { alias: 'High', value: 1 },
        { alias: 'Urgent', value: 2 }
      ],
      newJob: {}
    }
  },
  computed: {
    ...mapState('user', ['userDetails'])
  },
  mounted () {
    this.newJob = this.priority
  },
  methods: {
    ...mapActions('invitation', ['updateUserRole']),
    onClickOutside () {
      this.open = false
    },
    openSetting () {
      this.open = !this.open
    },
    changePriority (data) {
      if (this.localValue !== data.value) {
        this.newJob.priority = data.value
        this.$emit('onChange', this.newJob)
      }
      this.open = !this.open
    }
  }
}
</script>

<style lang="scss" scoped>
  .priority {
    .priority-label {
      font-family: 'ROBOTO';
      color: var(--secondary-text-color);
      padding: 0px 10px 0px 5px;
      font-size: 14px;
      text-transform: capitalize;
    }
    display: flex;
    align-items: center;
    width: 100%;
    .priority-wrap {
      width: 100%;
      text-align: center;
      position: relative;
      .priority-main {
        background: transparent;
        padding: 0px;
        width: 100%;
        border: none;
        height: 100%;
        .priority-container {
          display: flex;
          align-items: center;
          span {
            width: 2px;
            height: 17px;
            background-color: #2780eb;
          }
        }
      }
      .priority-options {
        position: absolute;
        left: 0px;
        z-index: 100;
        width: 70px;
        background: var(--background-color);
        border-radius: 4px;
        .priority-list {
          font-size: 14px;
          cursor: pointer;
          padding: 0px 5px;
          li {
            text-align: left;
            padding: 5px;
            font-family: 'ROBOTO';
            text-transform: capitalize;
          }
        }
      }
    }
  }
</style>
