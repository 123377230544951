<template>
  <div class="custom-table">
    <div class="columns m-0 is-justify-content-space-between is-align-items-center" v-if="!hideSearch">
      <div class="columns width-100 is-8 pl-0">
        <b-button
          class="mr-2"
          type="is-dark"
          size="is-small"
          icon-left="export"
          label="CSV"
          @click.prevent="exportCSVAndDownload" />
        <slot></slot>
      </div>
      <div class="column is-4 search-padding pr-0">
        <input-vue
          :required="false"
          :min="1"
          type="text"
          :max="150"
          placeholder="Search"
          @onChangeName="onChangeSearch"
          label="" />
      </div>
    </div>
    <div class="table-main">
      <table is-fullwidth
        v-if="data.length"
        :title-data="titleData">
        <tr>
          <th
            @click="sort(name.alias)"
            v-for="(name,index) of labels"
            :key="index">
            <div class="header-block">
              <p>{{ name.name }}</p>
              <div
                v-if="sortValidation.indexOf(name.alias)!=-1"
                class="sort-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                  active.by == 'asc' && active.field == name.alias ? 'active' : ''
                ]" v-bind:class="'rotate-sort'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 197.286L159.028 69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.286C1.807 200.9 0 205.184 0 210.132s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.428 12.85 5.428h255.813c4.949 0 9.233-1.811 12.848-5.428 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.232-5.427-12.846z"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                  active.by == 'desc' && active.field == name.alias ? 'active' : ''
                ]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"/></svg>
              </div>
            </div>
          </th>
          <th v-if="showAction">Actions</th>
        </tr>
        <tr
          v-for="(object, index) in data"
          :key="index">
          <td
            @click="onClickFun(object)"
            v-for="(name, labelindex) of labels"
            :key="labelindex">
            <p v-if="!(name.type)"
            @click="name.enableClick ? onClickCell(object) : null">
              <!-- <p v-if="!name.custom"> -->
              {{ (dateFormat.indexOf(name.alias)!=-1) ? getDate(object[name.alias]) : object[name.alias] }}
            </p>
            <div v-if="name.type && name.type === 'prioritySelector'" class="common-height">
              <priority-selector
                :showOptions="showOptions"
                :priority="object"
                :localValue="object[name.alias]"
                @onChange="onChangePriority" />
            </div>
          </td>
          <td v-if="showAction">
            <div class="action-button">
               <b-button
                v-if="showViewAction"
                class="edit-button"
                @click="onView(object)">
                <div class="edit-button-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449S19.748 20 12.015 20C4.835 20 0 11.551 0 11.551S4.446 4 12.015 4C19.709 4 24 11.551 24 11.551zM17 12c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/></svg>
                </div>
              </b-button>
               <b-button
                v-if="showRefreshAction"
                class="edit-button"
                @click="onActionFun('Restart',object)">
                <div class="edit-button-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" width="612.005" height="612.006" viewBox="0 0 612.005 612.006" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M605.731 267.776h-34.959C552.174 138.147 440.991 38.412 306.221 38.412c-104.15 0-194.175 59.635-238.404 146.487l71.791 27.6c32.742-58.22 95.033-97.633 166.614-97.633 92.471 0 169.595 65.675 187.294 152.909h-46.064c-5.905 5.734-11.563 9.29-3.096 19.229l71.312 72.517c5.906 5.734 15.482 5.734 21.389 0l71.791-72.517c5.886-5.733 2.789-13.475-3.117-19.228zm-299.51 229.365c-85.572 0-157.993-56.252-182.363-133.797h41.821c5.906-5.734 9.002-13.494 3.096-19.229l-71.771-72.517c-5.906-5.734-15.482-5.734-21.388 0L4.302 344.115c-8.468 9.939-2.81 13.494 3.096 19.229h35.055c20.738 113.383 130.756 210.25 263.787 210.25 97.346 0 182.286-52.16 229.115-129.877l-72.346-28.555c-34.557 49.524-91.841 81.979-156.788 81.979z"/></svg>
                </div>
              </b-button>
              <b-button
                v-if="showCancelAction"
                class="edit-button"
                @click="onActionFun('Cancel',object)">
                <div class="edit-button-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z"/></svg>
                </div>
              </b-button>
              <b-button
                v-if="showEditAction"
                class="edit-button"
                @click="onUpdateFun(object, index)">
                <div class="edit-button-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.947 383.947" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 303.947v80h80l236.053-236.054-80-80zM377.707 56.053L327.893 6.24c-8.32-8.32-21.867-8.32-30.187 0l-39.04 39.04 80 80 39.04-39.04c8.321-8.32 8.321-21.867.001-30.187z"/></svg>
                </div>
              </b-button>
              <b-button
                v-if="showDeleteAction"
                :disabled="!canDelete"
                class="edit-button"
                @click="onDeleteFun(object)">
                <div class="edit-button-svg">
                  <svg height="427pt" viewBox="-40 0 427 427.001" width="427pt" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M232.398 154.703c-5.523 0-10 4.477-10 10v189c0 5.52 4.477 10 10 10 5.524 0 10-4.48 10-10v-189c0-5.523-4.476-10-10-10zm0 0M114.398 154.703c-5.523 0-10 4.477-10 10v189c0 5.52 4.477 10 10 10 5.524 0 10-4.48 10-10v-189c0-5.523-4.476-10-10-10zm0 0"/><path d="M28.398 127.121V373.5c0 14.563 5.34 28.238 14.668 38.05A49.246 49.246 0 0078.796 427H268a49.233 49.233 0 0035.73-15.45c9.329-9.812 14.668-23.487 14.668-38.05V127.121c18.543-4.922 30.559-22.836 28.079-41.863-2.485-19.024-18.692-33.254-37.88-33.258h-51.199V39.5a39.289 39.289 0 00-11.539-28.031A39.288 39.288 0 00217.797 0H129a39.288 39.288 0 00-28.063 11.469A39.289 39.289 0 0089.398 39.5V52H38.2C19.012 52.004 2.805 66.234.32 85.258c-2.48 19.027 9.535 36.941 28.078 41.863zM268 407H78.797c-17.098 0-30.399-14.688-30.399-33.5V128h250v245.5c0 18.813-13.3 33.5-30.398 33.5zM109.398 39.5a19.25 19.25 0 015.676-13.895A19.26 19.26 0 01129 20h88.797a19.26 19.26 0 0113.926 5.605 19.244 19.244 0 015.675 13.895V52h-128zM38.2 72h270.399c9.941 0 18 8.059 18 18s-8.059 18-18 18H38.199c-9.941 0-18-8.059-18-18s8.059-18 18-18zm0 0"/><path d="M173.398 154.703c-5.523 0-10 4.477-10 10v189c0 5.52 4.477 10 10 10 5.524 0 10-4.48 10-10v-189c0-5.523-4.476-10-10-10zm0 0"/></svg>
                </div>
              </b-button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <pagination-vue
      v-if="data.length"
      :limit="limit"
      :totalCount="totalCount"
      :currentPage="currentPage"
      @onChangePage="onChangePage"
      :rowCount="data.length"
      @onChangeLimit="onChangeLimit" />
    <div v-if="!data.length " class="dashboard-container">
      <p v-if="!loading">No data available.</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import PaginationVue from '../../../components/WordCloud/Pagination.vue'
import InputVue from './Input.vue'
import { debounce, exportTableToCSV } from '../../../util/util'
import { mapActions, mapState } from 'vuex'
import Snackbar from '../../../components/Snackbar'
import prioritySelector from '../views/Jobs/PrioritySelector'

/** This component contains the code for the tables rendered on the admin panel */
export default {
  name: 'Table',
  components: {
    PaginationVue,
    InputVue,
    prioritySelector
  },
  props: {
    /** This prop contains the header names of the table */
    labels: {
      type: Array
    },
    /** This prop contains the data to be displayed in the table */
    data: {
      type: Array
    },
    /** Unsure */
    dateFormat: {
      type: Array
    },
    /** This prop defines the behavior for when the *update* button is clicked for a value in the row */
    onUpdate: {
      type: Function
    },
    /** This prop defines the behavior for when a row is clicked on */
    onClick: {
      type: Function
    },
    /** This prop defines the behavior for when the *view* button is clicked for a value in the row */
    onView: {
      type: Function
    },
    /** This prop defines the behavior for when the sorting criteria of the table is changed */
    onChangeSort: {
      type: Function
    },
    /** This prop contains the total count of rows in the table */
    totalCount: {
      type: [String, Number]
    },
    /** This prop indicates the current page number being viewed in the table */
    currentPage: {
      type: Number
    },
    /** This prop indicates the number of rows that are displayed at a time */
    limit: {
      type: Number
    },
    /** This prop defines the behavior for when the table is refreshed */
    updatePage: {
      type: Function
    },
    /** This prop defines the behavior for when a new row display limit is selected */
    updateLimit: {
      type: Function
    },
    /** This prop contains the sorting options */
    sortObj: {
      type: Object
    },
    /** This prop contains the text that is searched for in the table values */
    searchText: {
      type: String
    },
    /** Unsure */
    sortValidation: {
      type: Array
    },
    /** This prop indicates whether or not an item is deletable from the table */
    canDelete: {
      type: Boolean,
      default: false
    },
    /** This prop defines the behavior for when the *delete* button is clicked for a value in the row */
    onDelete: {
      type: Function
    },
    /** Unsure */
    onAction: {
      type: Function
    },
    /** This prop contains the text for the title-data of the table. This is being used while converting the table data into CSV file */
    titleData: {
      type: String,
      default: 'TableData'
    },
    /** This prop indicates whether the *edit* button is visible or not */
    showEditAction: {
      type: Boolean,
      default: true
    },
    /** This prop indicates whether the *refresh* button is visible or not (Jobs table) */
    showRefreshAction: {
      type: Boolean,
      default: false
    },
    /** This prop indicates whether the *cancel* button is visible or not (Jobs table) */
    showCancelAction: {
      type: Boolean,
      default: false
    },
    /** This prop indicates whether the *delete* button is visible or not */
    showDeleteAction: {
      type: Boolean,
      default: true
    },
    /** This prop indicates whether or not the **Actions** column is visible or not */
    showAction: {
      type: Boolean,
      default: true
    },
    /** This prop indicates whether or not the priority of a job can be changed. If set to false, the priority value will be read-only (Jobs Table) */
    showOptions: {
      type: Boolean,
      default: true
    },
    /** This prop indicates whether or not to show the *view/read values* button */
    showViewAction: {
      type: Boolean,
      default: false
    },
    /** This prop indicates whether or not to show the *view/read values* button */
    hideSearch: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: {
        by: null,
        field: null
      }
    }
  },
  computed: {
    ...mapState('categories', ['categoryList'])
  },
  mounted () {
    this.active = this.sortObj
  },
  methods: {
    ...mapActions('jobs', ['updateJobPriority']),
    debounceInput: debounce(function (e) {
      this.$emit('searchText', e, this.selectedCategories, this.$refs.commonSelection)
    }, 1000),

    getDate (date) {
      if (date === null) {
        return
      }
      return moment(date).format('MM-DD-YYYY')
    },
    onClickFun (data) {
      this.$emit('onClick', data)
    },
    onClickCell (data, name) {
      this.$emit('onClickCell', data, name)
    },
    onUpdateFun (data, index) {
      this.$emit('onUpdate', data, index)
    },
    sort (name) {
      if (this.sortValidation.indexOf(name) !== -1) {
        this.active = {
          by: (this.active.by === 'asc' && name === this.active.field) ? 'desc' : 'asc',
          field: name
        }
        this.$emit('onChangeSort', this.active, this.selectedCategories)
      }
    },
    onChangePage (page) {
      this.$emit('updatePage', page, this.selectedCategories)
    },
    onChangeLimit (limit) {
      this.$emit('updateLimit', limit, this.selectedCategories)
    },
    onChangeSearch (text) {
      this.debounceInput(text, this.selectedCategories)
    },
    onActionFun (type, object) {
      this.$emit('onAction', type, object)
    },
    onDeleteFun (object) {
      this.$emit('onDelete', object, this.selectedCategories)
    },
    exportCSVAndDownload () {
      if (this.data.length) {
        exportTableToCSV(this.$el, 'test.csv')
      } else {
        Snackbar({ message: 'No Data to export.', type: 'is-danger' })
      }
    },
    onFocus () {
      this.$refs.categories.$el.firstChild.focus()
      this.openCategories = true
    },
    async onChangePriority (object) {
      const res = await this.updateJobPriority(object)
      if (res === 200) {
        // const oldInstance = this.data.filter(j => j.job_id == object.job_id)[0]
        const oldInstance = this.data.find(j => j.job_id === object.job_id)
        if (oldInstance) {
          oldInstance.priority = object.priority
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  table {
    border-collapse: collapse;
    width: 100%;
  }
  .custom-table {
    padding: 10px;
    .width-100 {
      width: 100%;
      align-items: center;
    }
    .table-main {
      width: 100%;
      overflow-x: auto;
    }
    .dashboard-container {
      display: flex;
      height: 100px;
      align-items: center;
      justify-content: center;
    }
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
    .search-padding {
      padding: 0px 0px 10px 0px;
    }
  }
  td,
  th {
    border: var(--tertiary-border-color);
    text-align: left;
    padding: 8px;
    color: var(--secondary-text-color);
  }
  tr {
    cursor: pointer;
    &:hover:not(:first-child) {
      background-color: var(--hover-local-dropdown-background);
    }
  }
  th {
    .header-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        outline: none;
        width: 8px;
        fill: var(--secondary-text-color);
        height: 8px;
        display: flex;
      }
      .active {
        fill: rgba(128, 128, 128, 0.5);
      }
      .sort-container {
        display: flex;
        flex-direction: column;
        padding: 0px 5px 0px 10px;
      }
    }
  }
  td {
    .slug {
      text-decoration: underline;
      text-decoration-color: blue;
    }
    .action-button {
      display: flex;
      align-items: center;
      justify-content: center;
      .edit-button {
        padding: 3px;
        height: 100%;
        font-size: 12px;
        border: var(--tertiary-border-color);
        color: var(--pagination-deactive-text-color);
        background: transparent;
        .edit-button-svg {
          display: flex;
        }
        svg {
          outline: none;
          width: 12px;
          fill: var(--dropdown-images-backgroud);
          height: 12px;
        }
      }
      .edit-button:nth-child(1) {
        margin-right: 3px;
      }
      .edit-button:nth-child(2) {
        margin-left: 3px;
      }
      .edit-button:nth-child(3) {
        margin-left: 3px;
      }
    }
  }
  .columns {
    .column {
      .relative {
        position: relative;
      }
    }
  }
</style>
